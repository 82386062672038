<template>
  <div class="txt-black txt-16">
    <p>
      {{ $t('components.notifications.fieldTranslationsForCertainLanguagesAreMissing') }}
    </p>
    <ul class="pl-20">
      <li v-for="(fields, lang) in languages" :key="lang" class="txt-font-din-bold">{{ lang }} <span class="txt-font-din-regular">({{ $t('components.labels.numberOfFields', { number: fields }) }})</span></li>
    </ul>
    <p>{{ $t('components.notifications.pleaseProvideMissingTranslationsAndUploadAgain') }}</p>
  </div>
</template>

<script>
export default {
  props: {
    languages: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
